<template>
<div class="stresscheck-container">
	<article class="stresscheck">
		<h1 class="stresscheck__header">
			<img src="@/assets/img/stresscheck-diagnosis/title.png" alt="1分で診断! 医師監修 今の自分がわかるストレスチェック">
			<p><span>presented by</span><i class="stresscheck__header--icon sprite_stress logo" /></p>
		</h1>

		<!-- 紹介 -->
		<section class="stresscheck__intro">
			新型コロナの影響もあり、リモートワークが増え、外出の機会が減って、気分も落ち込みがちだったり、ストレスを感じている方も多いのではないでしょうか。以下の質問に答えてストレスをチェックしてみましょう。 <br>（質問は12問）<br><span> 監修:アイさくらクリニック 木村昌幹 先生</span>
		</section>
		<!-- /紹介 -->

		<!-- ストレスチェック -->
		<section class="stresscheck__questions">
			<ul class="stresscheck__questions--list">
				<li class="stresscheck__questions--item">
					<span class="stresscheck__questions--heading">Q.1</span>
					<p class="stresscheck__questions--text">頭痛が最近多くなった、<br>頭が重い感じがある</p>
					<div class="stresscheck__questions--label">
						<label>
							<input type="radio" name="q01" :value="1" v-model="question[0]">
							<i class="stresscheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q01" :value="0" v-model="question[0]">
							<i class="stresscheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="stresscheck__questions--item">
					<span class="stresscheck__questions--heading">Q.2</span>
					<p class="stresscheck__questions--text">めまいや立ちくらみがある、<br>目が疲れやすくなった</p>
					<div class="stresscheck__questions--label">
						<label>
							<input type="radio" name="q02" :value="1" v-model="question[1]">
							<i class="stresscheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q02" :value="0" v-model="question[1]">
							<i class="stresscheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="stresscheck__questions--item">
					<span class="stresscheck__questions--heading">Q.3</span>
					<p class="stresscheck__questions--text">下痢や便秘を繰り返したり、<br>胃もたれを感じたりする</p>
					<div class="stresscheck__questions--label">
						<label>
							<input type="radio" name="q03" :value="1" v-model="question[2]">
							<i class="stresscheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q03" :value="0" v-model="question[2]">
							<i class="stresscheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="stresscheck__questions--item">
					<span class="stresscheck__questions--heading">Q.4</span>
					<p class="stresscheck__questions--text">疲れが取れにくい、<br>朝からだるさを感じる</p>
					<div class="stresscheck__questions--label">
						<label>
							<input type="radio" name="q04" :value="1" v-model="question[3]">
							<i class="stresscheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q04" :value="0" v-model="question[3]">
							<i class="stresscheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="stresscheck__questions--item">
					<span class="stresscheck__questions--heading">Q.5</span>
					<p class="stresscheck__questions--text">食欲がなくなった、<br>以前より体重が減った</p>
					<div class="stresscheck__questions--label">
						<label>
							<input type="radio" name="q05" :value="1" v-model="question[4]">
							<i class="stresscheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q05" :value="0" v-model="question[4]">
							<i class="stresscheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="stresscheck__questions--item">
					<span class="stresscheck__questions--heading">Q.6</span>
					<p class="stresscheck__questions--text">寝つきが悪い、<br>夢を見ることが多い</p>
					<div class="stresscheck__questions--label">
						<label>
							<input type="radio" name="q06" :value="1" v-model="question[5]">
							<i class="stresscheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q06" :value="0" v-model="question[5]">
							<i class="stresscheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="stresscheck__questions--item">
					<span class="stresscheck__questions--heading">Q.7</span>
					<p class="stresscheck__questions--text">肩がこったり、<br>腰や背中が痛くなったりする</p>
					<div class="stresscheck__questions--label">
						<label>
							<input type="radio" name="q07" :value="1" v-model="question[6]">
							<i class="stresscheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q07" :value="0" v-model="question[6]">
							<i class="stresscheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="stresscheck__questions--item">
					<span class="stresscheck__questions--heading">Q.8</span>
					<p class="stresscheck__questions--text">仕事に対するやる気がわかない</p>
					<div class="stresscheck__questions--label">
						<label>
							<input type="radio" name="q08" :value="1" v-model="question[7]">
							<i class="stresscheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q08" :value="0" v-model="question[7]">
							<i class="stresscheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="stresscheck__questions--item">
					<span class="stresscheck__questions--heading">Q.9</span>
					<p class="stresscheck__questions--text">人に会うのをおっくうに感じる</p>
					<div class="stresscheck__questions--label">
						<label>
							<input type="radio" name="q09" :value="1" v-model="question[8]">
							<i class="stresscheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q09" :value="0" v-model="question[8]">
							<i class="stresscheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="stresscheck__questions--item">
					<span class="stresscheck__questions--heading">Q.10</span>
					<p class="stresscheck__questions--text">ちょっとしたことで腹が立ち、<br>イライラする</p>
					<div class="stresscheck__questions--label">
						<label>
							<input type="radio" name="q10" :value="1" v-model="question[9]">
							<i class="stresscheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q10" :value="0" v-model="question[9]">
							<i class="stresscheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="stresscheck__questions--item">
					<span class="stresscheck__questions--heading">Q.11</span>
					<p class="stresscheck__questions--text">急に息苦しくなったり、<br>胸が痛くなったりすることがある</p>
					<div class="stresscheck__questions--label">
						<label>
							<input type="radio" name="q11" :value="1" v-model="question[10]">
							<i class="stresscheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q11" :value="0" v-model="question[10]">
							<i class="stresscheck__questions--button" />
						</label>
					</div>
				</li>
				<li class="stresscheck__questions--item">
					<span class="stresscheck__questions--heading">Q.12</span>
					<p class="stresscheck__questions--text">メールの返信やSNSを<br>確認する頻度が減った</p>
					<div class="stresscheck__questions--label">
						<label>
							<input type="radio" name="q12" :value="1" v-model="question[11]">
							<i class="stresscheck__questions--button" />
						</label>
						<label>
							<input type="radio" name="q12" :value="0" v-model="question[11]">
							<i class="stresscheck__questions--button" />
						</label>
					</div>
				</li>
			</ul>
		</section>
		<!-- /ストレスチェック -->

		<!-- 結果をみる -->
		<a
			@click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=stresscheckDiagnosisRes`, _result: String(computedTotalAmount) } })"
			:class="[
				'stresscheck__button',
				{ 'is-eabled': computedEabledflag }
			]"
		>結果をみる</a>
		<!-- /結果をみる -->

		<section class="stresscheck__outro">
			<a @click="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } })">TOPにもどる</a>
		</section>
	</article>
</div>
</template>

<script>
import cmnConst from '@/assets/js/constant.js'

export default {
	name: 'stresscheckDiagnosis',
	components: {
	},
	data () {
		return {
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID,
			backendUrl: cmnConst.BACKEND_URL,
			question: []
		}
	},
	props: {
	},
	computed: {
		// ◯の数を算出する
		computedTotalAmount () {
			const joinArray = this.question.join(',')
			const count = (joinArray.match(/1/g) || []).length
			return count
		},

		// すべての設問を選択したかを算出する
		computedEabledflag () {
			return this.question.filter(item => typeof item !== 'undefined').length === 12
		}
	},
	mounted () {
		// NOTE: 背景色を変更する
		const addStyle = document.getElementsByTagName('body')[0]
		addStyle.style.backgroundColor = '#D9EBEA'
	},
	beforeDestroy () {
		// NOTE: 背景色を削除する
		const removeStyle = document.getElementsByTagName('body')[0]
		removeStyle.style.backgroundColor = null
	},
	methods: {
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/variable";

// NOTE: このページ限定の変数（デザインシステム外のため他での利用禁止）
$spacing-12: 0.75rem;
$spacing-13: 0.812rem;
$spacing-15: 0.937rem;
$spacing-28: 1.75rem;
$stresscheck-background: #D9EBEA;
$stresscheck-primary: #67B2AB;
$stresscheck-submit-background: #F1605C;
$stresscheck-submit-border: #C23F3C;
$stresscheck-submit-disabled-border: #999999;

.stresscheck {
	padding: 0;

	&-container {
		background-color: $stresscheck-background;
	}

	&__intro {
		font-family: $lp-family-primary;
		margin: $spacing-10 0 0 0;
		padding: 0 $spacing-14;
		font-size: $font-size-16;
		text-align: justify;
		color: $text-primary;
		span {
			font-size: $font-size-12;
		}
	}

	&__questions {
		$parent: &;
		width: 92.5334%;
		margin: $spacing-20 auto 0;

		&--list {
			margin: 0;
			padding: 0;
		}

		&--item {
			margin-top: $spacing-20;
			padding: $spacing-15 $spacing-20 $spacing-28;
			list-style: none;
			font-family: $lp-family-secondary;
			border: 2px solid $stresscheck-primary;
			border-radius: 10px;
			background-color: $background-primary;
			&:first-of-type { margin: 0; }
		}

		&--heading {
			display: block;
			color: $stresscheck-primary;
			font-size: $font-size-36;
			font-weight: bold;
			text-align: center;
			border-bottom: 1px dashed $stresscheck-primary;
		}

		&--text {
			margin: $spacing-15 0 0;
			text-align: center;
			font-size: $font-size-18;
			white-space: nowrap;

			@media (max-width: (360px)) {
				font-size: $font-size-16;
			}
		}

		&--label {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: $spacing-12;

			label {
				input[type="radio"] {
					display: none;
				}

				input[type="radio"] + #{$parent}--button {
					background-image: url(~@/assets/img/stresscheck-diagnosis/sprite_inp.png);
					background-size: (260px / 2) (372px / 2);
					background-repeat: no-repeat;
					display: block;
					background-position: 0 0;
					width: (260px / 2);
					height: (80px / 2);
					@media (max-width: (360px)) {
						background-size: (220px / 2) (314px / 2);
						width: (220px / 2);
						height: (68px / 2);
					}
				}

				&:nth-of-type(odd) {
					input[type="radio"] + #{$parent}--button {
						background-position: 0 0;
					}

					input[type="radio"]:checked + #{$parent}--button {
						background-position: 0 (-80px / 2);
						@media (max-width: (360px)) {
							background-position: 0 (-68px / 2);
						}
					}
				}

				&:nth-of-type(even) {
					margin-left: $spacing-20;

					input[type="radio"] + #{$parent}--button {
						background-position: 0 (-160px / 2);
						@media (max-width: (360px)) {
							background-position: 0 (-135px / 2);
						}
					}

					input[type="radio"]:checked + #{$parent}--button {
						background-position: 0 (-240px / 2);
						@media (max-width: (360px)) {
							background-position: 0 (-203px / 2);
						}
					}
				}
			}
		}
	}

	&__button {
		display: block;
		width: 80%;
		margin: $spacing-30 auto 0;
		padding: $spacing-13 0;
		box-sizing: border-box;
		background: $button-secondary;
		border-radius: 6px;
		text-align: center;
		color: $text-seconday;
		font-weight: bold;
		font-size: $font-size-24;
		box-shadow: 0 3px $stresscheck-submit-disabled-border;
		pointer-events: none;

		@media (max-width: (374px)) { font-size: $font-size-20; }
		@media (max-width: (330px)) { font-size: $font-size-18; }

		&.is-eabled {
			background: $stresscheck-submit-background;
			box-shadow: 0 3px $stresscheck-submit-border;
			pointer-events: auto;
		}
	}

	.sprite_stress {
		background-image: url(~@/assets/img/stresscheck-diagnosis/sprite_inp.png);
		background-size: (260px / 2) (372px / 2);
		background-repeat: no-repeat;
		display: block;

		&.logo {
			width: (180px / 2);
			height: (52px / 2);
			background-position: 0 (-320px / 2);
		}
	}

	&__outro {
		display: block;
		appearance: none;
		color: $text-seconday;
		font-size: $font-size-24;
		background: $button-primary;
		border-radius: 27px;
		width: 74.6667%;
		margin: $spacing-30 auto 0;
		padding: 10px 10px 8px;
		text-align: center;
		text-decoration: none;
	}
}
</style>
